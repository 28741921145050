import {faBookFont} from '@fortawesome/pro-light-svg-icons';
import {faCircleSmall} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getEscapedProductCode} from '@vantix/functions/isomorphic/compute/products';
import {encodeRTDBNumber} from '@vantix/functions/isomorphic/firebase';
import {calculatePriceWithVAT} from '@vantix/functions/isomorphic/products';
import {P_CheckoutStation} from '@vantix/rtdb-rules/default';
import BigNumber from 'bignumber.js';
import clsx from 'clsx';
import {memo, useCallback} from 'react';
import {useSelector} from 'react-redux';

import {db} from '@/dexie';
import {updateCurrentOrder} from '@/utils/indexeddb';
import {getCurrentSignedInOperatorID} from '@/utils/SharedDevice';
import {RTDB} from 'project-rtdb';
import store from 'project-store';

import TouchButton from '../TouchButton';
import WeightButton from '../WeightButton';

import classes from './index.module.scss';

export function getProductDataViaID(productID: string) {
    if (!productID) {
        return null;
    }

    return db.productData.get(productID);
}
export async function getProductDataViaCode(productCode: string) {
    if (!getEscapedProductCode(productCode)) {
        return null;
    }

    return getProductDataViaID((await db.productCodes.get(getEscapedProductCode(productCode)))?.ProductID);
}

export async function onPLU() {
    const storeState = store.getState();

    const writeBuffer = storeState.checkout.writeBuffer;
    const Product = await getProductDataViaCode(writeBuffer);

    if (!Product?.ID) {
        store.dispatch.checkout.update({
            pluError: 'not-found',
        });
        return;
    }
    if (Product.Disabled) {
        store.dispatch.checkout.update({
            pluError: 'disabled',
        });
        return;
    }

    let orderID = storeState.checkout.orderID;

    if (!orderID) {
        orderID = (
            await db.orders.put({
                ID: RTDB.newKey([...P_CheckoutStation, storeState.generics.SharedDeviceID, 'Sale']),
                Author: getCurrentSignedInOperatorID(),
                Created: encodeRTDBNumber(Date.now()),
                Status: 'draft',
                Payments: {},
                SoldItems: {},
                meta: {
                    sync: '1',
                },
            })
        );
        store.dispatch.checkout.update({
            orderID,
            focusedProductKey: '0',
        });
    }

    const numberOfItems = Object.keys((await db.orders.get(orderID))?.SoldItems || {}).length.toString();

    const ProductQuantity = BigNumber(storeState.checkout.multiplier || '1').toFixed(BigNumber(Product.UnitDecimals).toNumber());
    const Created = encodeRTDBNumber(Date.now());

    updateCurrentOrder(`SoldItems.${numberOfItems}`, {
        ProductID: Product.ID,
        Price: calculatePriceWithVAT(Product.Price, Product.VATRate).toFixed(2),
        Quantity: ProductQuantity,
        Created,
    });

    let index = 0;
    for (const [requiredProductID, multiplier] of Object.entries(Product.RequiresPurchaseOf || {})) {
        const requiredProduct = await getProductDataViaID(requiredProductID);

        if (!requiredProduct?.ID) {
            console.error(requiredProductID, 'not found');
            // todo: maybe log this remotely?
            continue;
        }

        updateCurrentOrder(`SoldItems.${numberOfItems}-${index++}`, {
            ProductID: requiredProduct.ID,
            Price: calculatePriceWithVAT(requiredProduct.Price, requiredProduct.VATRate).toFixed(2),
            Quantity: BigNumber(ProductQuantity).multipliedBy(multiplier).toFixed(BigNumber(requiredProduct.UnitDecimals).toNumber()),
            Created,
            RequiredBy: numberOfItems,
        });
    }

    store.dispatch.checkout.update({
        focusedProductKey: numberOfItems,
        writeBuffer: '',
        multiplier: '',
    });
}

function PLUButton() {
    return (
        <TouchButton
            className={classes['OnScreenNumpad__key']}
            style={{letterSpacing: '5px'}}
            onClick={() => onPLU()}
        >
            PLU
        </TouchButton>
    );
}

function Numpad() {
    const writeBufferContainsDot = useSelector(state => state.checkout.writeBuffer.includes('.'));
    const appendWriteBuffer = useCallback((toAppend: string) => {
        store.dispatch.checkout.update({
            writeBuffer: store.getState().checkout.writeBuffer + toAppend,
        });
    }, []);

    return (
        <div className={clsx(classes['OnScreenNumpad'])}>
            <TouchButton className={classes['OnScreenNumpad__key']} onClick={() => appendWriteBuffer('1')}>
                1
            </TouchButton>
            <TouchButton className={classes['OnScreenNumpad__key']} onClick={() => appendWriteBuffer('2')}>
                2
            </TouchButton>
            <TouchButton className={classes['OnScreenNumpad__key']} onClick={() => appendWriteBuffer('3')}>
                3
            </TouchButton>
            <TouchButton
                className={classes['OnScreenNumpad__key']}
                onClick={() => {
                    const number = BigNumber(store.getState().checkout.writeBuffer);

                    if (number.isGreaterThan(0)) {
                        store.dispatch.checkout.update({multiplier: number.toString()});
                    }
                    store.dispatch.checkout.update({writeBuffer: ''});
                }}
            >
                <FontAwesomeIcon icon={faCircleSmall} size="2xs" />
            </TouchButton>
            <TouchButton className={classes['OnScreenNumpad__key']} onClick={() => appendWriteBuffer('4')}>
                4
            </TouchButton>
            <TouchButton className={classes['OnScreenNumpad__key']} onClick={() => appendWriteBuffer('5')}>
                5
            </TouchButton>
            <TouchButton className={classes['OnScreenNumpad__key']} onClick={() => appendWriteBuffer('6')}>
                6
            </TouchButton>
            <PLUButton />
            <TouchButton className={classes['OnScreenNumpad__key']} onClick={() => appendWriteBuffer('7')}>
                7
            </TouchButton>
            <TouchButton className={classes['OnScreenNumpad__key']} onClick={() => appendWriteBuffer('8')}>
                8
            </TouchButton>
            <TouchButton className={classes['OnScreenNumpad__key']} onClick={() => appendWriteBuffer('9')}>
                9
            </TouchButton>
            <TouchButton
                disabled
                className={classes['OnScreenNumpad__key']}
            >
                <FontAwesomeIcon icon={faBookFont} />
            </TouchButton>
            <TouchButton className={classes['OnScreenNumpad__key']} disabled={writeBufferContainsDot} onClick={() => appendWriteBuffer('.')}>
                .
            </TouchButton>
            <TouchButton className={classes['OnScreenNumpad__key']} onClick={() => appendWriteBuffer('0')}>
                0
            </TouchButton>
            <TouchButton
                className={classes['OnScreenNumpad__key']}
                onClick={() => {
                    if (!store.getState().checkout.writeBuffer) {
                        store.dispatch.checkout.update({
                            multiplier: '',
                        });
                    }

                    store.dispatch.checkout.update({
                        writeBuffer: '',
                    });
                }}
            >
                C
            </TouchButton>
            <WeightButton
                className={classes['OnScreenNumpad__key']}
            />
        </div>
    );
}
export default memo(Numpad);
