import {faCircleExclamation, faScaleBalanced} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {randomUUID} from '@vantix/functions/isomorphic/uuid';
import {notification} from 'antd';
import BigNumber from 'bignumber.js';
import {useCallback, useRef} from 'react';

import Typography from '@/components/Typography';
import {useCurrentSharedDevice} from '@/utils/indexeddb';
import store from 'project-store';

import TouchButton, {TouchButtonProps} from '../TouchButton';

export default function WeightButton({onValue, ...props}: TouchButtonProps & {
    onValue?: (weight: string) => void,
}) {
    const ScaleConfig = useCurrentSharedDevice(sharedDevice => sharedDevice?.CheckoutStationConfig?.Scale);

    const nativePromiseRef = useRef<Promise<string>>(null);
    const onRequestWeight = useCallback(async () => {
        if (nativePromiseRef.current) {
            return;
        }

        try {
            nativePromiseRef.current = (async () => {
                const portID = await window.electron.serialPort.open({
                    path: ScaleConfig?.SerialPort,
                    baudRate: ScaleConfig?.Baudrate,
                });

                window.electron.serialPort.rawRead(portID); // flush read buffer
                window.electron.serialPort.write(portID, new Uint8Array([5, 18]));

                return window.electron.serialPort.read(portID, {
                    globalTimeout: 400,
                    decodeContent(content) {
                        const responseString = new TextDecoder().decode(content);
                        const matches = /[su]\s*([ -]\d*?\.\d*?)kg/gi.exec(responseString);

                        if (matches && matches.length > 0) {
                            if (matches[0][0].toLowerCase() === 'u') {
                                // reject unstable weight

                                return '-1';
                            }

                            const checksum = content[matches.index + matches[0].length];
                            const computedChecksum = [...matches[0]].reduce((acc, val) => acc ^ (val.charCodeAt(0)), 0); // eslint-disable-line no-bitwise

                            if (checksum === computedChecksum) {
                                return BigNumber(matches[1]).toString();
                            }
                        }
                    },
                });
            })();

            const weight = await nativePromiseRef.current;
            nativePromiseRef.current = null;

            if (BigNumber(weight).isGreaterThan('0.01')) {
                if (typeof onValue === 'function') {
                    onValue(BigNumber(weight).toFixed(3));
                }
                else {
                    store.dispatch.checkout.update({
                        multiplier: BigNumber(weight).toFixed(3),
                    });
                }
            }
        }
        catch (error) {
            nativePromiseRef.current = null;
            const notificationID = randomUUID();
            notification.error({
                key: notificationID,
                message: <Typography style={{paddingLeft: '8px'}} variant="h6" weight="medium">{error.message}</Typography>,
                icon: <FontAwesomeIcon icon={faCircleExclamation} size="xl" style={{color: 'red'}} />,
                duration: 5,
                closeIcon: null,
                showProgress: true,
                placement: 'topRight',
                onClick: () => notification.destroy(notificationID),
            });
        }
    }, [ScaleConfig, onValue]);

    return (
        <TouchButton
            {...props}
            disabled={!window.navigator.serial}
            onClick={onRequestWeight}
        >
            <FontAwesomeIcon icon={faScaleBalanced} />
        </TouchButton>
    );
}
